import http, { e } from "./http";
//import * as JWT from 'jwt-decode'
import jwt_decode from "jwt-decode";

const STORAGE_NAME = "hkctollbo";

const roles = {
  Root: 1,
  Administrator: 2,
  Supervisor: 3,
  ShiftChief: 4,
  TollCollector: 5,
  Auditor: 6,
  Maintenance: 7,
};

const loginService = {
  login(username, password) {
    return http
      .post(e("security/login"), {
        username,
        password,
      })
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem(STORAGE_NAME, JSON.stringify(response.data));

          return {
            success: true,
            data: response.data,
          };
        }

        return {
          success: false,
          message: response.data.message,
          data: response.data.data,
        };
      })
      .catch((err) => {
        return {
          success: false,
          err: err,
        };
      });
  },
  requestRestorePassword(username) {
    return http
      .put(e(`User/RequestRestorePassword/${username}`), null, {})
      .then((r) => {
        return r.data;
      });
  },
  restorePassword(data) {
    return http.put(e("User/RestorePassword"), data, {}).then((r) => {
      return r.data;
    });
  },
  getImage() {
    return http
      .get(e(`User/Image?timestamp=${new Date().getTime()}`), null, {})
      .then((d) => {
        return d.data;
      });
  },
  isJwtAlive: function (jwt) {
    const now = Date.now().valueOf() / 1000;

    if (typeof jwt.exp !== "undefined" && jwt.exp < now) return false;
    if (typeof jwt.nbf !== "undefined" && jwt.nbf > now) return false;

    return true;
  },
  isAuthenticated: function () {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token == null) {
      return false;
    }

    var jwt = this.getSessionToken();

    return this.isJwtAlive(jwt);
  },
  getRawToken: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token) {
      var parsedToken = JSON.parse(token);
      return parsedToken.accessToken;
    }

    if (redirect) {
      location.href = "/";
    }

    return null;
  },
  getSessionToken: function (redirect = true) {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token) {
      var parsedToken = JSON.parse(token);
      return jwt_decode(parsedToken.accessToken);
    }

    if (redirect) {
      location.href = "/";
    }

    return null;
  },
  getPermissions() {
    var token = localStorage.getItem(STORAGE_NAME);
    if (token) {
      var parsed = JSON.parse(token);
      return parsed.permissions || [];
    }

    return [];
  },
  logout() {
    localStorage.removeItem(STORAGE_NAME);
    localStorage.clear();

    location.href = "/";
  },
  getUserData() {
    var jwt = this.getSessionToken();
    return jwt;
  },
  getRoles() {
    return roles;
  },
  getId() {
    const data = this.getUserData();
    return data["User::Id"];
  },
  isRoot() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.Root;
  },
  isAdmin() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.Administrator;
  },
  isSupervisor() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.Supervisor;
  },
  isShiftChief() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.ShiftChief;
  },
  isTollCollector() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.TollCollector;
  },
  isAuditor() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.Auditor;
  },
  isMaintenance() {
    const data = this.getUserData();
    return data["User::RoleId"] == roles.Maintenance;
  },
  hasPermission(permission) {
    const permissions = this.getPermissions().map((p) => p.key);
    return permissions.includes(permission);
  },
};

export default loginService;
