<template>
  <v-app-bar color="white" light>
    <v-spacer />
    <v-chip label :color="connectionStatusColor" dark>
      {{ connectionState }}
      <v-progress-circular
        v-if="!isReady"
        indeterminate
        color="white"
        size="18"
        width="2"
        class="ml-2"
      />
    </v-chip>
    <div>
      <v-menu bottom :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item two-line v-bind="attrs" v-on="on">
            <v-list-item-avatar color="grey lighten-1">
              <!-- <v-icon> mdi-shield-account </v-icon> -->
              <v-img :src="base64image" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle>{{ role }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import loginService from "@/services/login.service";
import userService from "@/services/user.service";
import { mapGetters } from "vuex";
export default {
  name: "top-nav",
  data: () => ({
    closeOnClick: true,
    name: "",
    role: "",
    user: {},
    isReady: false,
    connectionStatusColor: "red lighten-2",
  }),
  computed: {
    ...mapGetters("communication", ["connectionState"]),
    base64image() {
      return this.user.avatarBase64
        ? `data:image/png;base64,${this.user.avatarBase64}`
        : "";
    },
  },
  watch: {
    connectionState(val) {
      switch (val) {
        case "Connected":
          {
            this.connectionStatusColor = "green accent-3";
            this.isReady = true;
          }
          break;
        case "Reconnecting":
          {
            this.connectionStatusColor = "yellow lighten-1";
            this.isReady = false;
          }
          break;
        default: {
          this.connectionStatusColor = "red lighten-2";
          this.isReady = false;
        }
      }
    },
  },
  mounted() {
    this.loadUserData();
  },
  methods: {
    loadUserData() {
      var data = loginService.getUserData();
      this.name =
        data["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
      this.role =
        data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      console.log(data);

      userService.getSelf().then((response) => {
        this.user = response;
      });
    },
  },
};
</script>