import http, { e } from "./http";
import loginService from "./login.service";

var userService = {
  getUsersPaginated(pagination) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e("user/search"), pagination, headers).then((r) => {
      return r.data;
    });
  },
  getRoles() {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e("user/roles"), null, headers).then((r) => {
      return r.data;
    });
  },
  createUser(model) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.post(e("User"), model, headers).then((r) => {
      return r.data;
    });
  },
  updateUser(model) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.put(e("User"), model, headers).then((r) => {
      return r.data;
    });
  },
  deleteUser(userId) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.remove(e(`User/${userId}`), headers).then((r) => {
      return r.data;
    });
  },
  forceUpdatePassword(userId, newPassword) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http
      .put(
        e(`User/force-password`),
        {
          userId: userId,
          password: newPassword,
        },
        headers
      )
      .then((r) => {
        return r.data;
      });
  },
  getPermissionsByRole(idRole) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http
      .get(e(`User/role/${idRole}/permissions`), null, headers)
      .then((r) => {
        return r.data;
      });
  },
  updateRolePermissions(idRole, permissions) {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http
      .put(
        e(`User/role/${idRole}/permissions`),
        {
          permissions: permissions,
        },
        headers
      )
      .then((r) => {
        return r.data;
      });
  },
  getSelf() {
    const token = loginService.getRawToken();

    var headers = {
      Authorization: "Bearer " + token,
    };

    return http.get(e("User/self"), null, headers).then((r) => {
      return r.data;
    });
  },
};

export default userService;
