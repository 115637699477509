import * as SignalR from "@microsoft/signalr";

const communicationModule = {
  namespaced: true,
  state: () => ({
    socket: null,
  }),
  mutations: {},
  getters: {
    connectionState: (state) => {
      return state.socket?.connectionState || "null";
    },
  },
  actions: {
    initConnection(context) {
      const BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/notifications`;

      context.state.socket = new SignalR.HubConnectionBuilder()
        .configureLogging(SignalR.LogLevel.Information)
        .withUrl(BASE_URL, {
          //skipNegotiation: true,
          //transport: SignalR.HttpTransportType.WebSockets,
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: () => {
            return 1000;
          },
        })
        .build();

      //context.state.socket.serverTimeoutInMilliseconds = 1000;

      var start = async () => {
        if (
          context.state.socket.state === SignalR.HubConnectionState.Disconnected
        ) {
          try {
            await context.state.socket.start();
            console.log("SignalR Connected.");

            context.dispatch("sendMessage", {
              type: "ClientConnected",
              content: {},
            });
          } catch (err) {
            console.log(err);
            setTimeout(start, 5000);
          }
        }
      };

      context.state.socket.onreconnecting(() => {
        console.log("Connection lost. Reconnecting...");
      });

      context.state.socket.onreconnected(() => {
        console.log("Reconnected...");

        context.dispatch("sendMessage", {
          type: "ClientConnected",
          content: {},
        });
      });

      context.state.socket.onclose(async () => {
        console.log("Connection closed. Retrying...");
        await start();
      });

      context.state.socket.on("CommittedTransactionEvent", () => {
        //   console.log(content);
      });

      context.state.socket.on("UpdateGateDeviceStatus", () => {
        //   console.log(content);
      });

      context.state.socket.on("UpdateGateDeviceEvent", () => {
        //   console.log(content);
      });

      context.state.socket.on("message", (gateId, type, transaction, content) =>
        console.log(gateId, type, transaction, content)
      );

      start();
    },
    stopCommunication(context) {
      if (context.state.socket != null) {
        context.state.socket.stop();
      }
    },
    sendMessage(context, { gateId, type, transaction, content }) {
      if (
        context.state.socket != null &&
        context.state.socket?.connectionState == "Connected"
      ) {
        context.state.socket
          .send(
            "SendMessage",
            gateId,
            type,
            transaction,
            JSON.stringify(content)
          )
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};

export default communicationModule;
